import React from "react";
import classNames from "classnames";

import * as styles from "./Title.module.scss";

interface TitleProps {
  className?: string;
  children: string;
  tagName?: keyof Pick<
    JSX.IntrinsicElements,
    "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span"
  >;
  size?: "small" | "normal" | "large";
}

const Title: React.FC<TitleProps> = ({
  className,
  size = "normal",
  children,
  tagName: El = "p",
}) => {
  return (
    <El className={classNames(styles.title, [styles[size]], className)}>
      {children}
    </El>
  );
};

export { Title };

import React, { useMemo } from "react";
import { graphql, PageProps } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { CollectionPage } from "../components/CollectionPage";
import { SimpleTile } from "../components/Common/SimpleTile";

interface CategoryTemplateProps {
  projects: {
    edges: Array<{
      node: {
        title: string;
        subtitle?: string;
        slug: {
          current: string;
        };
        mainImage: {
          asset: {
            gatsbyImageData: IGatsbyImageData;
          };
        };
      };
    }>;
  };
  category: {
    title: string;
    description: string;
  };
}

const CategoryTemplate: React.FC<PageProps<CategoryTemplateProps>> = ({
  data,
}) => {
  return (
    <CollectionPage
      title={data.category.title}
      description={data.category.description}
    >
      {data.projects.edges.map(({ node }) => (
        <SimpleTile
          key={node.title}
          to={`/project/${node.slug.current}`}
          image={node.mainImage.asset.gatsbyImageData}
          title={node.title}
          subtitle={node.subtitle}
        />
      ))}
    </CollectionPage>
  );
};

export const query = graphql`
  query CategoryPageQuery($category: String!) {
    projects: allSanityProject(
      filter: {
        categories: { elemMatch: { title: { eq: $category } } }
        slug: { current: { ne: null } }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          subtitle
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData(aspectRatio: 1)
            }
          }
        }
      }
    }
    category: sanityCategory(title: { eq: $category }) {
      title
      description
    }
  }
`;

export default CategoryTemplate;

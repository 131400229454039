import React from "react";
import classNames from "classnames";
import { GatsbyImageProps, GatsbyImage, getImage } from "gatsby-plugin-image";

import { Container } from "../Common/Container";
import { Markdown } from "../Common/Markdown";
import { Title } from "../Common/Title";

import * as styles from "./CollectionPage.module.scss";

interface CollectionPageProps {
  className?: string;
  title: string;
  description: string;
  image?: {
    asset: GatsbyImageProps["image"];
  };
}

const CollectionPage: React.FC<CollectionPageProps> = ({
  className,
  children,
  title,
  description,
  image,
}) => {
  return (
    <Container tagName="section" className={classNames(styles.page, className)}>
      <div className={styles.left}>
        <Title size="normal">{title}</Title>

        {description && <Markdown>{description}</Markdown>}
        {image && <GatsbyImage image={getImage(image.asset)} alt="" />}
      </div>
      <div className={styles.content}>{children}</div>
    </Container>
  );
};

export { CollectionPage };

import React from "react";
import classNames from "classnames";

import * as styles from "./Container.module.scss";

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  tagName?: keyof Pick<
    JSX.IntrinsicElements,
    "div" | "section" | "span" | "aside" | "footer" | "header" | "nav" | "main"
  >;
}

const Container: React.FC<ContainerProps> = ({
  tagName: El = "div",
  children,
  className,
}) => {
  return (
    <El className={classNames(styles.container, className)}>{children}</El>
  );
};

export { Container };

import React from "react";
import classNames from "classnames";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";

import { InternalExternalLink } from "../InternalExternalLink";

import * as styles from "./SimpleTile.module.scss";

interface SimpleTileProps {
  image?: IGatsbyImageData;
  title: string;
  subtitle?: string;
  to?: string;
  className?: string;
}

const SimpleTile: React.FC<SimpleTileProps> = ({
  title,
  subtitle,
  to,
  image,
  className,
}) => {
  return (
    <InternalExternalLink
      fallBackElement="div"
      to={to}
      className={classNames(styles.tile, className)}
    >
      {image && (
        <span className={styles.tileImage}>
          <GatsbyImage image={image} alt="" />
        </span>
      )}
      <span className={styles.tileTitle}>{title}</span>
      {subtitle && <span className={styles.tileSubtitle}>{subtitle}</span>}
    </InternalExternalLink>
  );
};

export { SimpleTile };
